












import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import Location from '@/models/Location';

@Component({
  components: {
    LocationManageContentComponent: () => import(
        '@/components/location/LocationManageContent.component.vue'),
    SideCardComponent: () => import(
        '@/components/shared/SideCard/SideCard.component.vue'),
  },
})
/**
 * SideCard wrapper for the LocationManageContentComponent
 */
export default class LocationManageMasterDataComponent extends Vue {
  @VModel({default: false})
  public showLocationModal!: boolean;
  @Prop({default: undefined})
  public locationId: string | undefined;
  @Prop({default: undefined})
  public selectedCustomer!: Customer;


  private locationManageDone(location: Location) {
    this.$emit('done', location);
    this.showLocationModal = false;
  }
}
